import React, {Fragment, useEffect} from 'react'
import { styled } from '@mui/material/styles';
import {navigate, RouteComponentProps} from '@reach/router';
import {
  Button, CardActions,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
  Icon,
  Paper,
  Typography,
} from '@mui/material';
import Layout from '../components/Layout/Layout';
import ListItem from '../components/ListItem/ListItem';
import {withSnackbar, WithSnackbarProps} from 'notistack';
import {
  deleteMakeup,
  DeleteMakeup,
  findOneMakeup,
  FindOneMakeup, restoreMakeup, updateMakeup,
} from '../redux/modules/makeups/makeups.thunks';
import {connect, useDispatch} from 'react-redux';
import {RootState} from '../redux';
import {MakeupData, MakeupType} from '../redux/modules/makeups/makeups.interfaces';
import dayjs from "../libs/dayjs";
import Lightbox from "yet-another-react-lightbox";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/styles.css";

const PREFIX = 'Details';

const classes = {
  paper: `${PREFIX}-paper`,
  loaderContainer: `${PREFIX}-loaderContainer`,
  buttonsContainer: `${PREFIX}-buttonsContainer`,
  button: `${PREFIX}-button`,
  removeButton: `${PREFIX}-removeButton`,
  restoreButton: `${PREFIX}-restoreButton`
};

const StyledLayout = styled(Layout)((
  {
    theme
  }
) => ({
  [`& .${classes.paper}`]: {
    padding: theme.spacing(2),
  },

  [`& .${classes.loaderContainer}`]: {
    display: 'flex',
    justifyContent: 'center',
    padding: '16px',
  },

  [`& .${classes.buttonsContainer}`]: {
    marginLeft: 'auto',
    marginBottom: '16px',
    display: 'flex',
    justifyContent: 'flex-end',
  },

  [`& .${classes.button}`]: {
    marginRight: 16,
  },

  [`& .${classes.removeButton}`]: {
    marginRight: '16px',
    backgroundColor: theme.palette.error.light,
  },

  [`& .${classes.restoreButton}`]: {
    marginRight: '16px',
    backgroundColor: theme.palette.divider,
  }
}));

interface Props {
  id: string,
}

interface DetailsPageReduxProps {
  isLoading: boolean,
  makeupData: MakeupData,
  deleteMakeup: DeleteMakeup,
  findOneMakeup: FindOneMakeup,
}

const DetailsPage: React.FunctionComponent<RouteComponentProps & Props & WithSnackbarProps & DetailsPageReduxProps> = ({
  id, enqueueSnackbar, isLoading, makeupData, deleteMakeup, findOneMakeup, location
}) => {
  const dispatch = useDispatch();
  const [openRemoveDialog, setOpenRemoveDialog] = React.useState(false);
  const [openRestoreDialog, setOpenRestoreDialog] = React.useState(false);
  const [openTransformDialog, setOpenTransformDialog] = React.useState(false);
  const [lightboxOpen, setLightboxOpen] = React.useState(false);
  const [lightboxIndex, setLightboxIndex] = React.useState(0);
  const createdAtDate = dayjs(makeupData.createdAt).format('DD.MM.YY HH:mm');
  const updatedAtDate = dayjs(makeupData.updatedAt).format('DD.MM.YY HH:mm');

  const handleDelete = async () => {
    try {
      await deleteMakeup(id);
      enqueueSnackbar((() => {
        switch (makeupData.type) {
          case MakeupType.TRIAL_WEDDING:
            return 'Makijaż próbny ślubny został usunięty';
          case MakeupType.GROOM:
            return 'Poprawka pana młodego została usunięta';
          case MakeupType.WEDDING:
            return 'Makijaż ślubny został usunięty';
          case MakeupType.RESERVATION:
            return 'Rezerwacja została usunięta';
          case MakeupType.MAKEUP:
            return 'Makijaż został usunięty';
          case MakeupType.LESSON:
            return 'Lekcja makijażu została usunięta';
          case MakeupType.BREAK:
            return 'Przerwa została usunięta';
          case MakeupType.MODEL:
            return 'Modelka została usunięta';
        }
      })(),{
        variant: 'success',
      });
      navigate('/');
    } catch (e) {
      enqueueSnackbar('Wystąpił błąd', { variant: 'error'});
    }
  }

  const handleRestore = async () => {
    try {
      await dispatch(restoreMakeup(id));
      enqueueSnackbar((() => {
        switch (makeupData.type) {
          case MakeupType.TRIAL_WEDDING:
            return 'Makijaż próbny ślubny został przywrócony';
          case MakeupType.GROOM:
            return 'Poprawka pana młodego została przywrócona';
          case MakeupType.WEDDING:
            return 'Makijaż ślubny został przywrócony';
          case MakeupType.MAKEUP:
            return 'Makijaż został przywrócony';
          case MakeupType.RESERVATION:
            return 'Rezerwacja została przywrócona';
          case MakeupType.LESSON:
            return 'Lekcja makijażu została przywrócona';
          case MakeupType.BREAK:
            return 'Przerwa została przywrócona';
          case MakeupType.MODEL:
            return 'Modelka została przywrócona';
        }
      })(),{
        variant: 'success',
      });
      navigate('/archive');
    } catch (e) {
      enqueueSnackbar('Wystąpił błąd', { variant: 'error'});
    }
  }

  const handleReservationTransform = async () => {
    try {
      await dispatch(updateMakeup(id, {
        ...makeupData,
        peopleCount: `${makeupData.peopleCount}`,
        type: MakeupType.WEDDING,
      } as any));

      enqueueSnackbar("Rezerwacja została przepisana",{
        variant: 'success',
      });
      navigate(`/makeup/${id}/edit`);
    } catch (e) {
      enqueueSnackbar('Wystąpił błąd', { variant: 'error'});
    }
  }

  const handleLightboxOpen = (i: number) => {
    setLightboxIndex(i);
    setLightboxOpen(true);
  }

  useEffect(() => {
    const fetchData = async () => {
      await findOneMakeup(id);
    }

    fetchData();
  }, [id, findOneMakeup]);

  const renderMakeupNotes = () => {
    if (!makeupData.notes) {
      return null;
    }
    return makeupData.notes.split('\n').map((text, index) => (
        <p key={index}>{text}</p>
    ))
  }
  return (
    <StyledLayout>
      <div className={classes.buttonsContainer}>
        {makeupData.is_deleted ? (
            <Button variant="contained" className={classes.restoreButton} onClick={() => setOpenRestoreDialog(true)}>
              <Icon>restore</Icon>
            </Button>
        ) : (
          <Button variant="contained" className={classes.removeButton} onClick={() => setOpenRemoveDialog(true)}>
            <Icon>delete</Icon>
          </Button>
        )}
        {makeupData.type === MakeupType.RESERVATION && (
          <Button variant="contained" color="primary" className={classes.button} onClick={() => setOpenTransformDialog(true)}>
            <Icon>event_available</Icon>
          </Button>
        )}
        <Button variant="contained" color="primary" onClick={() => navigate(`/makeup/${id}/edit`, { state: (location && location.state) || {}})}>
          <Icon>edit</Icon>
        </Button>
      </div>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            { isLoading && (
              <div className={classes.loaderContainer}>
                <CircularProgress />
              </div>
            )}

            { makeupData._id && (
              <Fragment>
                <ListItem
                  key={makeupData._id}
                  details
                  {...makeupData}
                />
                {makeupData.type !== MakeupType.BREAK && (
                  <>
                    <Typography variant="body2" style={{ marginBottom: '8px' }}>
                      Telefon: { makeupData.phone }
                    </Typography>
                    {makeupData.type !== MakeupType.MODEL && (
                      <Typography variant="body2" style={{ marginBottom: '8px' }}>
                        Skąd napisała: { makeupData.source }
                      </Typography>
                    )}
                    {makeupData.instagram && (
                      <Typography variant="body2" style={{ marginBottom: '8px' }}>
                        Nick na instagramie: { makeupData.instagram } <a href={`https://instagram.com/${makeupData.instagram}`} target="_blank" rel="noopener noreferrer">Przejdź</a>
                      </Typography>
                    )}
                  </>
                )}
                <Typography variant="body1">
                  {renderMakeupNotes()}
                </Typography>
                <CardActions sx={{
                  justifyContent: 'flex-end',
                  textAlign: 'right',
                  ['& h6']: { opacity: 0.8 }
                }}>
                  <Typography variant="subtitle2">
                    Dodano: {createdAtDate}
                    { createdAtDate !== updatedAtDate && (
                      <>
                        <br/>
                        Edycja: {updatedAtDate}
                      </>
                    )}
                  </Typography>
                </CardActions>

                {!!makeupData.photos?.length && (
                  <>
                    <Typography variant={"h6"}>Aktualne zdjęcia</Typography>
                    <Grid container spacing={2}>
                      {makeupData.photos?.map((url, i) => (
                        <Grid item xs={6} sm={4} sx={{ position: 'relative' }} onClick={() => handleLightboxOpen(i)}>
                          <img src={`${process.env.REACT_APP_PHOTOS_URL}/${url}`} style={{ objectFit: 'contain', width: '100%' }} />
                        </Grid>
                      ))}
                    </Grid>
                  </>
                )}
              </Fragment>
            )}
          </Paper>
        </Grid>
      </Grid>

      <Lightbox
          plugins={[Zoom]}
          open={lightboxOpen}
          close={() => setLightboxOpen(false)}
          slides={makeupData.photos?.map(url => ({ src: `${process.env.REACT_APP_PHOTOS_URL}/${url}` }))}
          index={lightboxIndex}
      />

      <Dialog
        open={openRemoveDialog}
        onClose={() => setOpenRemoveDialog(false)}
      >
        <DialogTitle id="alert-dialog-title">{"Czy chcesz usunąć makijaż?"}</DialogTitle>
        <DialogActions>
          <Button onClick={() => setOpenRemoveDialog(false)} color="primary">
            Anuluj
          </Button>
          <Button onClick={() => handleDelete()} color="primary" autoFocus>
            Usuń
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
          open={openRestoreDialog}
          onClose={() => setOpenRestoreDialog(false)}
      >
        <DialogTitle id="alert-dialog-title">{"Czy chcesz przywrócić makijaż?"}</DialogTitle>
        <DialogActions>
          <Button onClick={() => setOpenRestoreDialog(false)} color="primary">
            Anuluj
          </Button>
          <Button onClick={() => handleRestore()} color="primary" autoFocus>
            Przywróć
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
          open={openTransformDialog}
          onClose={() => setOpenTransformDialog(false)}
      >
        <DialogTitle id="alert-dialog-title">{"Czy chcesz potwierdzić rezerwacje?"}</DialogTitle>
        <DialogActions>
          <Button onClick={() => setOpenTransformDialog(false)} color="primary">
            Anuluj
          </Button>
          <Button onClick={() => handleReservationTransform()} color="primary" autoFocus>
            Potwierdź
          </Button>
        </DialogActions>
      </Dialog>
    </StyledLayout>
  );
}

const mapStateToProps = (state: RootState) => ({
  isLoading: state.makeups.isLoading,
  makeupData: state.makeups.makeup,
})

const mapDispatchToProps = {
  deleteMakeup,
  findOneMakeup,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(DetailsPage));
